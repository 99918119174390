import { mixPanelEvent } from "@features/activities/data";
import { Store, StoreProvider } from "@features/stores/domain";

export enum StoresEvents {
  // Link Store
  CLICK_LINK_STORE_BUTTON = "click_link_store_button",
  CLICK_LINK_STORE_SUCCESSFUL_MESSAGE = "click_link_store_successful_message",
  CLICK_LINK_STORE_FAILURE_MESSAGE = "click_link_store_failure_message",
  // Unlink Store
  CLICK_UNLINK_STORE_SUCCESSFUL_MESSAGE = "click_unlink_store_successful_message",
  CLICK_UNLINK_STORE_BUTTON = "click_unlink_store_button",
  CLICK_UNLINK_STORE_FAILURE_MESSAGE = "click_unlink_store_failure_message",
}

export function trackConnectingStore(provider: StoreProvider) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_BUTTON, {
    provider,
  });
}

export function trackStoreConnectionSuccess(data: {
  provider: StoreProvider;
  storeId: string;
}) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_SUCCESSFUL_MESSAGE, {
    provider: data.provider,
    storeId: data.storeId,
  });
}

export function trackStoreConnectionFailure(data: {
  provider: StoreProvider;
  error: string;
}) {
  mixPanelEvent(StoresEvents.CLICK_LINK_STORE_FAILURE_MESSAGE, {
    provider: data.provider,
    error: data.error,
  });
}

export function trackDisconnectStoreButtonClicked(store: Store) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_BUTTON, {
    provider: store.provider,
    storeId: store.id,
  });
}

export function trackDisconnectStoreSuccess(store: Store) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_SUCCESSFUL_MESSAGE, {
    provider: store.provider,
    storeId: store.id,
  });
}

export function trackDisconnectStoreFailure(store: Store, error: string) {
  mixPanelEvent(StoresEvents.CLICK_UNLINK_STORE_FAILURE_MESSAGE, {
    provider: store.provider,
    storeId: store.id,
    error,
  });
}
