import { StoreCourse, StoreProvider } from "@features/stores/domain";

export const storeCourses: Record<any, StoreCourse> = {
  [StoreProvider.EasyOrders]: {
    title: "stores.courses.easyOrders.title",
    lessons: [
      {
        title: "stores.courses.easyOrders.firstCourse",
        link: "https://taager.com/sa/learning-center/easy-orders1",
      },
      {
        title: "stores.courses.easyOrders.secondCourse",
        link: "https://taager.com/sa/learning-center/easy-orders2",
      },
      {
        title: "stores.courses.easyOrders.thirdCourse",
        link: "https://taager.com/sa/learning-center/easy-orders3",
      },
      {
        title: "stores.courses.easyOrders.fourthCourse",
        link: "https://taager.com/sa/learning-center/easy-orders4",
      },
    ],
  },
  [StoreProvider.YouCan]: {
    title: "stores.courses.youcan.title",
    lessons: [
      {
        title: "stores.courses.youcan.firstCourse",
        link: "https://taager.com/sa/learning-center/youcan1",
      },
      {
        title: "stores.courses.youcan.secondCourse",
        link: "https://taager.com/sa/learning-center/youcan2",
      },
    ],
  },
  [StoreProvider.WooCommerce]: {
    title: "stores.courses.woocommerce.title",
    lessons: [
      {
        title: "stores.courses.woocommerce.firstCourse",
        link: "https://taager.com/sa/learning-center/taager-wordpress-plugin1",
      },
    ],
  },
  [StoreProvider.Zid]: {
    title: "stores.courses.zid.title",
    lessons: [
      {
        title: "stores.courses.zid.firstCourse",
        link: "https://taager.com/sa/learning-center/taager-zid-plugin1",
      },
    ],
  },
};
