export type DukanData = {
  state: DukanStatus;
  url: string;
  slug?: string;
  name?: string;
  contactInfo: ContactInfoEntity;
  integrations: AdsIntegrationEntity;
  hasEmbeddedCheckoutEnabled: boolean;
};

type ContactInfoEntity = {
  email: string;
  phoneNumber: string;
};

type AdsIntegrationEntity = {
  tiktokPixel: {
    ids: string[];
  };
  googleTagCode: {
    ids: string[];
  };
};

export enum DukanStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
}
