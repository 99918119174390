// append urls here, DO NOT remove this line

import { Category } from "@features/catalog/domain/entities";

export const URLS = {
  careers: "https://taager.bamboohr.com/careers",
  helpCenter: "/help-center",
  home: "/",
  designSystem: "/design-system",
  onboardingQuestionnaire: "/questionnaire/onboarding",
  auth: {
    login: "/auth/login",
    forgetPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:id/:token",
    oldResetPassword: "/reset-password/:id/:token",
    register: "/auth/signup",
    loginWithEmail: "/auth/login-with-email",
  },
  profile: "/profile",
  wallet: "/wallet",
  orders: "/orders",
  landingPage: "/product-checkout",
  preOrderRequests: "/pre-order-requests",
  challenges: "/incentive-program",
  contactUs: "/contact-us",
  termsConditions: "/terms-conditions",
  privacyPolicy: "/privacy-policy",
  pages: {
    learningCenter: "/learning-center",
    wallet: "/wallet",
    orders: "/orders",
    shippingInfo: "/info",
    complaintsAndSuggestions: "/complain-suggest",
  },
  incentiveProgram: "/incentive-program",
  loyaltyProgram: "/loyalty",
  referral: "/referral",
  analytics: "/insights",
  preOrder: "/pre-order",
  stores: "/stores",
  zidIntegration: "/zid-integration",
  youcanIntegration: "/youcan-integration",
  dukan: {
    settings: "/stores/edit-store",
  },
  categories: {
    viewCategory: (category: Category) => `/products/category/${category.id}`,
  },
  products: {
    list: "/products",
    catalog: "/products/catalog",
    viewProducts: "/products/category",
  },
  cart: "/cart",
  uxProgram: "https://uxprogram.taager.com",
};

export const completedUrlsPages = [
  URLS.home,
  URLS.auth.login,
  URLS.auth.forgetPassword,
  URLS.auth.resetPassword,
  URLS.auth.register,
  URLS.termsConditions,
  URLS.profile,
  URLS.stores,
  URLS.zidIntegration,
  URLS.youcanIntegration,
];
