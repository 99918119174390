import { country } from "@features/country/data";
import { storesList } from "@features/stores/data";
import { UserStoreModel } from "@features/stores/data/models";
import {
  EasyOrdersApiKey,
  Store,
  StoreProvider,
} from "@features/stores/domain";
import { trans } from "@taager/localization";

export function mapEasyOrderStore(data: any): Store<EasyOrdersApiKey> | void {
  return {
    ...storesList[StoreProvider.EasyOrders],
    isConnected: true,
    canBeDisconnected: false, // can not disconnect easy orders store
    shouldDisplayConnectionState: false,
    data: {
      apiKey: data.apiKey,
      get expiresAt() {
        return convertIsoDateStringToFormattedDate(data.expiresAt);
      },
    },
  };
}

/**
 * Convert the given iso date string to formatted date string x Day x Month x Year
 */
function convertIsoDateStringToFormattedDate(isoDateString: string): string {
  const date = new Date(isoDateString);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${trans(`months.${month}`)} ${year}`;
}

export function mapUserStores(data: UserStoreModel[]): Store[] {
  return data.map(store => {
    const storeProvider = store.provider.toLowerCase();
    return {
      ...storesList[storeProvider],
      id: store.storeId,
      provider: storeProvider,
      isConnected: true,
      shouldDisplayConnectionState: true,
    };
  });
}

export function mapStoresList({
  allStores,
  userStores,
  dukan,
  easyOrders,
}: {
  allStores: Store[];
  userStores: Store[];
  dukan: Store | void;
  easyOrders: Store | void;
}): Store[] {
  const prepareStoreUrl = (url?: string) => {
    if (!url) return "";

    if (!url.startsWith("http")) {
      return `https://${url}`;
    }

    return url;
  };

  // update stores list to have the installed user stores so we can have a unified list
  const storesList = allStores.map(store => {
    const storeProvider = store.provider.toLowerCase();
    const userStore = userStores.find(
      userStore => userStore.provider === storeProvider,
    );

    return {
      ...store,
      ...userStore,
      isConnected: !!userStore,
      canBeDisconnected: !!userStore,
    };
  });

  const finalStoresList = [easyOrders, ...storesList, dukan] as Store[];

  // now we need to merge them all and filter by supported countries
  return finalStoresList
    .filter(store => {
      if (!store) return false;

      return store.supportedCountries.includes(country.code);
    })
    .map(store => ({
      ...store,
      url: prepareStoreUrl(store.id),
      get name() {
        return trans(store.name);
      },
    }));
}
