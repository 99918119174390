import { country } from "@features/country/data";
import {
  BaseRepository,
  parseError,
  promiseAllObject,
} from "@features/shared/data";
import {
  DukanData,
  DukanStatus,
  EasyOrdersApiKey,
  Store,
  StoreProvider,
  type StoresRepositoryContract,
} from "@features/stores/domain";
import {
  ConnectStoreData,
  ConnectStoreResponse,
} from "@features/stores/domain/types/store.types";
import { storesApiUrl } from "../constants";
import {
  trackConnectingStore,
  trackDisconnectStoreFailure,
  trackDisconnectStoreSuccess,
  trackStoreConnectionFailure,
  trackStoreConnectionSuccess,
} from "../events";
import { mapEasyOrderStore, mapStoresList, mapUserStores } from "../mappers";
import { UserStoreModel } from "../models";
import { storesList } from "../utils/all-stores.data";
import {
  generateEasyOrdersExpireTime,
  isEasyOrderApiKeyInActive,
} from "../utils/easy-orders.utils";

export class StoresRepository
  extends BaseRepository
  implements StoresRepositoryContract
{
  /**
   * Return all tores list
   */
  public async list(): Promise<Store[]> {
    const result = await promiseAllObject({
      easyOrders: this.getEasyOrderStore(),
      dukan: this.getDukanStore(),
      userStores: this.getUserStores(),
      allStores: this.getAllStores(),
    });

    return mapStoresList(result);
  }

  /**
   * Connect the given store
   */
  public async connect(
    store: Store,
    payload: ConnectStoreData,
  ): Promise<ConnectStoreResponse> {
    try {
      trackConnectingStore(store.provider);

      const { data } = await this.http.post<
        ConnectStoreData & { provider: StoreProvider },
        ConnectStoreResponse
      >(storesApiUrl.connectStore, {
        provider: store.provider,
        redirectUri: payload.redirectUri,
        authCode: payload.authCode,
      });

      store.id = data.storeId;
      store.isConnected = true;
      store.canBeDisconnected = true;

      trackStoreConnectionSuccess({
        provider: store.provider,
        storeId: data.storeId,
      });

      return data;
    } catch (error) {
      trackStoreConnectionFailure({
        provider: store.provider,
        error: parseError(error),
      });

      throw error;
    }
  }

  /**
   * Disconnect the given store
   */
  public async disconnect(store: Store): Promise<void> {
    try {
      await this.http.delete(storesApiUrl.disconnectStore(store.id!));

      store.isConnected = false;
      store.canBeDisconnected = false;

      trackDisconnectStoreSuccess(store);
    } catch (error) {
      trackDisconnectStoreFailure(store, parseError(error));

      throw error;
    }
  }

  /**
   * Get Dukan Store
   */
  protected async getDukanStore(): Promise<Store<DukanData> | void> {
    if (
      !storesList[StoreProvider.Dukan].supportedCountries.includes(country.code)
    )
      return;

    try {
      const { data } = await this.http.get<DukanData>(
        storesApiUrl.getDukanStore,
      );

      return {
        ...storesList[StoreProvider.Dukan],
        isConnected: data.state === DukanStatus.ACTIVE,
        canBeDisconnected: false, // can not disconnect dukan store
        data,
        shouldDisplayConnectionState: true,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          ...storesList[StoreProvider.Dukan],
          isConnected: false,
        } as Store<DukanData>;
      }

      return;
    }
  }

  /**
   * Get Easy Order Store
   */
  protected async getEasyOrderStore(): Promise<Store<EasyOrdersApiKey> | void> {
    if (
      !storesList[StoreProvider.EasyOrders].supportedCountries.includes(
        country.code,
      )
    )
      return;

    const { data } = await this.http.get<EasyOrdersApiKey[]>(
      storesApiUrl.getEasyOrdersApiKey,
    );

    if (isEasyOrderApiKeyInActive(data)) {
      // generate new key

      const payload = {
        expiresAt: generateEasyOrdersExpireTime(),
      };

      const { data: newKey } = await this.http.post<
        typeof payload,
        EasyOrdersApiKey
      >(storesApiUrl.generateEasyOrdersApiKey, payload);

      return mapEasyOrderStore(newKey);
    }

    return mapEasyOrderStore(data[0]);
  }

  /**
   * Get user stores
   */
  protected async getUserStores(): Promise<Store[]> {
    const { data } = await this.http.get<UserStoreModel[]>(
      storesApiUrl.userStores,
    );

    return mapUserStores(data);
  }

  /**
   * Get all stores list
   */
  protected async getAllStores(): Promise<Store[]> {
    return [
      storesList[StoreProvider.YouCan],
      storesList[StoreProvider.Zid],
      storesList[StoreProvider.WooCommerce],
    ];
  }
}
