import { localeCodesList } from "@features/drivers/data";
import { parseBasePath, URLS } from "@features/shared/presentation";
import { setApps, type RouterConfigurations } from "@taager/react-router";
import frontOfficeApp from "app/app-modules.json";
import React from "react";

setApps([frontOfficeApp]);

export const routerConfigurations: RouterConfigurations = {
  strictMode: false,
  get basePath() {
    return parseBasePath();
  },
  appendLocaleCodeToUrl: false,
  autoRedirectToLocaleCode: false,
  forceRefresh: true,
  scrollToTop: "smooth",
  localization: {
    // hard reload is recommended if the application is large as it will make a full reload
    changeLanguageReloadMode: "soft",
    localeCodes: Object.keys(localeCodesList),
  },
  lazyLoading: {
    // loadingComponent: React.lazy(
    //   () => import("design-system/Indicators/ProgressBar"),
    // ),
    loaders: {
      app: () => {
        return import("app/app-main");
      },
      module: (_app: string, module: string) => {
        return import(`../app/${module}/main.ts`);
      },
    },
  },
  notFound: {
    mode: "redirect",
    path: URLS.home,
  },
  rootComponent: React.lazy(() => import("layouts/Root")),
};
