import { BaseRepository } from "@features/shared/data";
import { storesApiUrl } from "@features/stores/data/constants";
import {
  DukanData,
  DukanStatus,
  DukanStoreCreatePayload,
  Store,
  type DukanRepositoryContract,
} from "@features/stores/domain";

export class DukanRepository
  extends BaseRepository
  implements DukanRepositoryContract
{
  public async create(
    store: Store<DukanData>,
    payload: DukanStoreCreatePayload,
  ): Promise<Store<DukanData>> {
    const { data } = await this.http.post<DukanStoreCreatePayload, DukanData>(
      storesApiUrl.createDukanStore,
      payload,
    );

    store.data = data;
    store.isConnected = true;
    store.canBeDisconnected = false;
    store.shouldDisplayConnectionState = true;

    return store;
  }

  public async reactivate(store: Store<DukanData>): Promise<Store<DukanData>> {
    const { data } = await this.http.put<
      {
        state: DukanStatus.ACTIVE;
      },
      DukanData
    >(storesApiUrl.reactivateDukanStore, {
      state: DukanStatus.ACTIVE,
    });

    store.data = data;
    store.isConnected = true;
    store.canBeDisconnected = false;
    store.shouldDisplayConnectionState = true;

    return store;
  }

  public async activate() {
    //
  }

  public async deactivate() {
    //
  }

  public async update(_data: any): Promise<Store> {
    //
    return {} as Store;
  }
}
